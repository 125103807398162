import {
  AlcatrazLocales,
  alcatrazLocales,
  rtlLocales,
} from "@/config/supported-countries";
import { DesignSystemComponents, PageMetaDataT } from "@/types/design-system-types";
import { Locales } from "@/config/supported-countries";
import { clsx, type ClassValue } from "clsx";
import { NextRouter } from "next/router";
import { twMerge } from "tailwind-merge";
import { Entry } from "contentful";

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

// ! Falls back to locale if locale has no region (e.g. en, zh)
// ? Expected format: en-GB, de-AT, zh-HK
// ? Expected output: en, de, zh
export const getLanguageFromLocale = (locale: string) => {
  const localeSplit = locale.split("-");
  if (localeSplit.length === 2) return localeSplit[0].toLowerCase();

  return locale || "en";
};

export const getRegionFromLocale = (locale: string) => {
  const localeSplit = locale.split("-");
  if (localeSplit.length === 2) return localeSplit[1].toLowerCase();
  // Default to Ireland so crypto is available in the pillar
  // This could be an issue for Alcatraz, we might need to have a separate check for that (ie isAlcatraz)
  return "ie";
};

export const getContentfulEnvironment = (
  router: NextRouter
): {
  env: "live-preview" | "preview" | "final" | "live" | "live-uncached";
  isLivePreview: boolean;
  isPreview: boolean;
  isLiveUncached: boolean;
  isLive: boolean;
} => {
  if (router.pathname.includes("/preview/") && router.query.live_preview === "true") {
    return {
      env: "live-preview",
      isLivePreview: true,
      isPreview: false,
      isLive: false,
      isLiveUncached: false,
    };
  }

  if (router.pathname.includes("/preview/")) {
    return {
      env: "preview",
      isLivePreview: false,
      isPreview: true,
      isLive: false,
      isLiveUncached: false,
    };
  }

  if (router.pathname.includes("/final/")) {
    return {
      env: "final",
      isLivePreview: false,
      isPreview: false,
      isLive: false,
      isLiveUncached: false,
    };
  }

  if (router.asPath.includes("/live/")) {
    return {
      env: "live-uncached",
      isLivePreview: false,
      isPreview: false,
      isLive: true,
      isLiveUncached: true,
    };
  }

  return {
    env: "live",
    isLivePreview: false,
    isPreview: false,
    isLive: true,
    isLiveUncached: false,
  };
};

/**
 * Converts xx-xx to xx-XX
 *
 * @param locale en-gb, en-au, en
 * @param original If the alcatraz/pillar locales should be masked (pillar -> en, alcatraz -> eo)
 * @returns en-GB, en-AU, en
 */
export const formatLocale = (locale: Locales, original: boolean = false): Locales => {
  let formatted: Locales = locale;

  // if (!locale) return "en";
  if (!locale) {
    formatted = "en";
  }

  // Sanitize (remove hash and query values)
  if (locale.includes("#") || locale.includes("?")) {
    formatted = locale.split(/[#?]/)[0] as Locales;
  }

  formatted = formatted.includes("-")
    ? (`${formatted.split("-")[0]}-${formatted.split("-")[1].toUpperCase()}` as Locales)
    : (formatted as Locales);

  // Unmask the original Contentful locale (pillar -> en, alcatraz -> eo)
  if (original) {
    if (formatted === "pillar") {
      formatted = "en";
    } else if (formatted === "en") {
      formatted = "eo";
    }
  }

  return formatted;
  // return locale.includes("-")
  //   ? (`${locale.split("-")[0]}-${locale.split("-")[1].toUpperCase()}` as Locales)
  //   : (locale as Locales);
};

/**
 *
 * @param router The Next.js router object (useRouter())
 * @param original If the alcatraz/pillar locales should be masked (pillar -> en, alcatraz -> eo)
 */
export const getRouteDetails = (
  router: any,
  original: boolean = false
): {
  locale: Locales;
  language: string;
  region: string;
  slug: string; // The slug of the page
  isStaticRoute: boolean; // True if the route is a repo page (not Contentful)
  isRTL: boolean; // Left to right or right to left language
  shouldHideMenuAndFooter?: boolean;
} => {
  let locale: Locales = "en";
  let isRTL = false;

  // This function will unmask the original contentful locale (pillar -> en, alcatraz -> eo)
  const convertToOriginal = (l: Locales) => {
    if (l === "pillar") {
      return "en";
    } else if (l === "en") {
      return "eo";
    }

    return l;
  };

  const shouldHideMenuAndFooter = router?.query?.d === "hide";

  // Handle 404 contentful pages
  if (
    router.asPath.includes("entry.fields.slug") &&
    router.asPath.includes("_not_found")
  ) {
    locale = formatLocale(router.asPath.match(/\[(.*?)\]/)[1]) as Locales;
    locale = original ? convertToOriginal(locale) : locale;

    const region = getRegionFromLocale(locale);
    const language = getLanguageFromLocale(locale);
    isRTL = rtlLocales.includes(locale);

    return {
      locale,
      region,
      language,
      slug: "404",
      isStaticRoute: false,
      isRTL,
      shouldHideMenuAndFooter,
    };
  }

  if (router.asPath.includes("menu-editor") || router.asPath.includes("footer-editor")) {
    // Create a dummy URL to extract the query params
    const url = new URL(`http://localhost${router.asPath}`);
    let locale: Locales = url.searchParams.get("locale") as Locales;

    locale = formatLocale(locale);
    locale = original ? convertToOriginal(locale) : locale;

    const language = getLanguageFromLocale(locale);
    const region = getRegionFromLocale(locale);
    isRTL = rtlLocales.includes(locale);

    const editor = router.asPath.includes("menu-editor")
      ? "menu-editor"
      : "footer-editor";

    return {
      locale,
      language,
      region,
      slug: editor,
      isStaticRoute: false,
      isRTL,
      shouldHideMenuAndFooter,
    };
  }

  // Preview ENVs
  if (
    router.asPath.split("/")[1] === "preview" ||
    // router.asPath.split("/")[1] === "final" || // !! Deprecated
    router.asPath.split("/")[1] === "live"
  ) {
    locale = formatLocale(router.asPath.split("/")[2]);
    locale = original ? convertToOriginal(locale) : locale;

    const language = getLanguageFromLocale(locale);
    const region = getRegionFromLocale(locale);
    const slug = "/" + router.asPath.split("/").slice(3).join("/");
    isRTL = rtlLocales.includes(locale);

    const isStaticRoute =
      router.pathname.includes("/[locale]/[...dynamic_slug]") ||
      router.pathname.includes("/[locale]")
        ? false
        : true;

    return {
      locale,
      language,
      region,
      slug,
      isStaticRoute,
      isRTL,
      shouldHideMenuAndFooter,
    };
  }

  // Actual live site
  locale = formatLocale(router.asPath.split("/")[1]);
  locale = original ? convertToOriginal(locale) : locale;
  const language = getLanguageFromLocale(locale);
  const region = getRegionFromLocale(locale);
  isRTL = rtlLocales.includes(locale);

  const slug = "/" + router.asPath.split("/").slice(2).join("/");

  const isStaticRoute =
    router.pathname === "/[locale]/[...dynamic_slug]" || router.pathname === "/[locale]"
      ? false
      : true;

  return { locale, slug, region, language, isStaticRoute, isRTL };
};

// @ts-nocheck
export const numberFormatter = (locale: string = "en-GB") => ({
  // singleDecimal: new Intl.NumberFormat(locale, {
  //   style: "decimal",
  //   maximumFractionDigits: 1,
  // }),
  doubleDecimal: new Intl.NumberFormat(locale, {
    style: "decimal",
    maximumFractionDigits: 2,
  }),
  fourDecimal: new Intl.NumberFormat(locale, {
    style: "decimal",
    maximumFractionDigits: 4,
  }),
});

// Detect negative symbols at start of a string (scandi regions use a slightly different hyphen (−))
export const isNegative = (value: string | null | undefined): boolean => {
  if (!value) {
    return false;
  }

  return /^[−-]/.test(value.trim());
};

export const getCookie = (cname: string) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const randomIntWith = (digits: number): number => {
  if (digits <= 0) {
    throw new Error("Number of digits must be greater than 0");
  }
  const min = Math.pow(10, digits - 1);
  const max = Math.pow(10, digits) - 1;
  return Math.floor(min + Math.random() * (max - min + 1));
};

export const setCookie = (cname: string, cvalue: any, exdays: any) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const debounce = (func: Function, delay: number) => {
  let debounceTimer: any;
  return function (this: unknown) {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

export const getMobileOS = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return "os-android";
  }
  if (/iPad|iPhone|iPod/.test(ua)) {
    return "os-ios";
  }
  return "os-other";
};

export const isMacOS = () => {
  const ua = navigator.userAgent;
  const macOSRegex = /Mac OS X [0-9_]+/;
  const isMacOS = macOSRegex.test(ua);
  return isMacOS;
};

export const getDesktopOS = () => {
  if (getDeviceType() === "desktop") {
    return isMacOS() ? "os-mac" : "os-other-desktop";
  }
  return null;
};

export const slugify = (str: string) => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

export const removeWhitespace = (str: string): string => {
  if (str === undefined) return "";

  return str && str.replace(/\s/g, "");
};

export const kebabCase = (str: string) => {
  return str
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();
};

// Convert kebab-case to CamelCase
export const kebabToCamelCase = (str: string) => {
  return str
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
};

export const dateConverter = (d: string) => {
  const date = new Date(d);

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date.getTime())) {
    return date;
  }
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  let day: string | number = date.getDate();

  if (day < 10) {
    day = `0${day}`;
  }

  return `${day} ${month[date.getMonth()]} ${date.getFullYear()}`;
};

// Load external scripts. Was purpose built to load onboarding JS as it requires
// reinitialization on every page load
export const injectExternalScript = (
  scriptSrc: string,
  scriptId: string,
  shouldDefer: boolean = true
) => {
  const script = document.createElement("script");
  script.src = scriptSrc;
  script.id = scriptId;
  script.defer = shouldDefer;

  // Remove any existing script to ensure it reloads
  const existingScript = document.querySelector('script[src="' + scriptSrc + '"]');
  if (existingScript) {
    existingScript.remove();
  }

  document.body.appendChild(script);
};

// Cleanup external scripts in useEffect. This accompanies the injectExternalScript function
export const cleanupExternalScript = (scriptId: string) => {
  const script = document.getElementById(scriptId);
  if (script) {
    script.remove();
  }
};

// This function is used to override the onboarding params for the MT4 page
export const overrideOnboardingParams = (pathname: string) => {
  const isMT4Page = pathname.includes("mt4");
  if (isMT4Page) {
    return "jid=gb2&amp;iaid=null&amp;lsrc=1";
  }
  return undefined;
};

// Check if object is empty
export const isEmpty = (obj: object): boolean => {
  return Object.keys(obj).length === 0;
};

/**
 * Useful for checking if the page is within contentful live preview
 * @returns {boolean} - True if the current page is in an iframe
 */
export const isLivePreview = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

// An export function which checks if the given string starts with http or https and returns the correct URL.
export const addHttps = (url?: string) => {
  if (url === undefined) return "";
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  } else {
    return `https:${url}`;
  }
};

// Example url: https://signup.cmcmarkets.com/#/emailRegistration?jid=gb1&iaid=null&lsrc=1
// We only care about the string after the question mark
export const sliceOnboardingParams = (url: string | undefined) => {
  if (url === undefined) return "";
  const params = url.split("?")[1];
  return params;
};

export const scrollToSection = (id: string, offset = 150) => {
  // Get the section element to scroll to
  const elem = document.querySelector(`[data-component-id="${id}"]`);

  if (elem) {
    const elemPosition = elem.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({
      top: elemPosition - offset, // Apply the offset here
      behavior: "smooth",
    });
  }
};

export const scrollToID = (id: string, offset = 150) => {
  const elem = document.getElementById(id);

  if (elem) {
    const elemPosition = elem.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({
      top: elemPosition - offset,
      behavior: "smooth",
    });
  }
};

export const pluralizeWord = (word: string, count: number) => {
  return count === 1 ? word : `${word}s`;
};

export const calculateJSONSizeInKB = (obj: any): number => {
  const jsonString = JSON.stringify(obj);
  const bytes = new TextEncoder().encode(jsonString).length;
  return bytes / 1024;
};

export const stripData = (data: any) => {
  function stripExtraData(obj: any): any {
    // Check if obj is undefined or null
    if (obj == null || !obj?.fields) {
      // console.log("obj is null or undefined");
      return obj;
    }

    let result: any = {};

    // Ensure obj is not undefined or null before accessing its properties
    if (obj && obj.hasOwnProperty("fields")) {
      result.fields = {};
      for (const key in obj.fields) {
        // Recursively process each field
        result.fields[key] = stripExtraData(obj.fields[key]);
      }
    } else {
      // If it's not an object with `fields`, return the object itself
      return obj;
    }

    // Ensure obj is not undefined or null before accessing its properties
    if (obj && obj.hasOwnProperty("sys")) {
      result.sys = {
        contentType: obj.sys.contentType ?? null,
        id: obj.sys.id ?? null,
        locale: obj.sys.locale ?? null,
      };
    }

    return result;
  }

  // const strippedComponents = stripExtraData(data?.items[0]?.fields?.components);
  const strippedComponents = stripExtraData(data?.items[0]?.fields?.components);

  const status: any = {};

  if (data?.items?.[0]?.fields.status.isLive !== undefined)
    status.isLive = data?.items?.[0]?.fields.status.isLive;

  if (data?.items?.[0]?.fields.status.isReadyForLive !== undefined)
    status.isReadyForLive = data?.items?.[0]?.fields.status.isReadyForLive;

  if (data?.items?.[0]?.fields.status.status !== undefined)
    status.status = data?.items?.[0]?.fields.status.status;

  return {
    total: data?.total ? data?.total : 0,

    items: [
      {
        sys: {
          contentType: data?.items?.[0]?.sys.contentType,
          id: data?.items?.[0]?.sys.id,
          locale: data?.items?.[0]?.sys.locale,
          space: data?.items?.[0]?.sys.space,
        },
        fields: {
          status,
          internalName: data?.items?.[0]?.fields?.internalName,
          slug: data?.items?.[0]?.fields.slug,
          title: data?.items?.[0]?.fields.title,
          description: data?.items?.[0]?.fields.description,
          menu: data?.items?.[0]?.fields.menu,
          components: strippedComponents,
        },
      },
    ],
  };
};

/**
 * Removed all non-essential fields from the entry object
 *
 * @param entry Contentful entry object
 * @param fieldsToRemove Extra fields to remove from all nested objects
 * @param referencedEntries The CMA references
 * @returns
 */
export const stripEntry = (
  entry: any,
  fieldsToRemove?: string[],
  referencedEntries?: any,
  currentLocale?: Locales
) => {
  const stripExtraData = (obj: any): any => {
    if (obj == null) {
      return obj;
    }

    // Check if this is a reference that's in draft state - if so, return nothing
    if (obj.sys && obj.sys.id && currentLocale && referencedEntries) {
      const ref = referencedEntries.find((ref: any) => ref.sys.id === obj.sys.id);

      if (ref && ref.sys.fieldStatus?.["*"]?.[currentLocale] === "draft") {
        return null; // This will be filtered out in array processing
      }
    }

    if (Array.isArray(obj)) {
      const processed = obj.map(stripExtraData).filter((item) => item !== null);
      return processed.length > 0 ? processed : null;
    }

    let result: any = {};

    // Process sys
    if (obj.sys) {
      result.sys = {
        id: obj.sys.id ?? null,
        contentType: obj.sys.contentType ?? null,
        locale: obj.sys.locale ?? null,
        type: obj.sys.type ?? null,
      };
    }

    // Process fields
    if (obj.fields) {
      result.fields = {};
      for (const key in obj.fields) {
        if (fieldsToRemove && fieldsToRemove.includes(key)) continue;
        result.fields[key] = stripExtraData(obj.fields[key]);
      }
    } else {
      // If it's not an object with `fields`, return the object itself
      return obj;
    }

    return result;
  };

  return stripExtraData(entry);
};

// Returns the ID of the closest parent component by type
export const findParentComponentIdByType = (
  element: HTMLElement,
  parentComponentType: DesignSystemComponents
): string | undefined => {
  const component = element.closest(
    `[component-name="${parentComponentType}"]`
  ) as HTMLElement;
  return component?.getAttribute("component-id") || undefined;
};

/**
 * Adds dots, commas etc based on locales
 *
 * @param num The number to format
 * @param locale  The locale to use for formatting
 * @returns The same number but with commas/dots separating the thousands
 */
export const localizedNumber = (num: number | string, locale: string) => {
  if (typeof num === "string") {
    num = parseFloat(num);
  }

  return new Intl.NumberFormat(locale).format(num);
};

// export const writeToFile = (data: any, fileName: string) => {
//   // Convert the array to a JSON string
//   const jsonString = JSON.stringify(data, null, 2);

//   // Define the file path
//   const filePath = path.join(fileName);

//   // Write the JSON string to a file
//   writeFile(filePath, jsonString, "utf8", (err) => {
//     if (err) {
//       console.error("Error writing file:", err);
//     } else {
//       console.log("File has been written successfully.");
//     }
//   });
// };

export let writeToFile: (data: string, filename: string) => void;

if (typeof window === "undefined") {
  // Running in Node.js
  const { writeFileSync } = require("fs");

  writeToFile = (data: string, filename: string) => {
    writeFileSync(filename, data, "utf-8");
  };
} else {
  // Running in the browser
  writeToFile = (data: string, filename: string) => {
    console.log("writeToFile is not available in the browser environment.");
  };
}
// export { writeToFile };

export const isAlcatraz = (locale: Locales) => {
  return alcatrazLocales.includes(locale as AlcatrazLocales);
};

/**
 * Extracts the metadata slug from the nPageMetadata field slot of an entry
 *
 * @param entry The entry with the embedded metadata
 * @param locale Required locale
 * @returns
 */
export const extractMetadataSlug = (
  entry: Entry<any>,
  locale: Locales,
  isLivePreview?: boolean
) => {
  const unmaskedLocale = (() => {
    if (isLivePreview) return locale;

    switch (locale) {
      case "pillar":
        return "en";
      case "en":
        return "eo";

      default:
        return locale;
    }
  })();

  let nPageMetaData: Entry<PageMetaDataT> | null = null;

  if (entry?.fields?.nPageMetadata) {
    nPageMetaData = entry.fields.nPageMetadata as Entry<PageMetaDataT>;
  }

  if (entry?.sys?.contentType?.sys?.id === "nPageMetadata") {
    nPageMetaData = entry as unknown as Entry<PageMetaDataT>;
  }

  // !! Does not work due to fallbacks !!
  // If the slug is directly in the new slug field (new method)
  if (false && nPageMetaData?.fields?.slug) {
    return nPageMetaData?.fields?.slug;
  }

  // If the slug is in the slugs array in metadata
  const metadataSlugs = nPageMetaData?.fields?.metadata?.slugs;
  return metadataSlugs && metadataSlugs[unmaskedLocale];
};

export const deepEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key)) return false;
    if (!deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
};
